import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faDownload, faFileExcel, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function InviteRegionalStaff({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    const [openSuccessModal, setOpenSuccessModal] = useState(false); // To control modal visibility
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.xlsx')) {
                // Handle XLSX file
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryString = e.target.result;
                    const workbook = XLSX.read(binaryString, { type: 'binary' });
                    const worksheet = workbook.Sheets['Sheet1'];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);

                    const childCompaniesData = jsonData.map(row => ({
                        locationName: row['Location Name'] || '',
                        contactName: row['Name of the Contact'] || '',
                        contactEmail: row['Contact Email Address'] || '',
                        locationAddress: row['Address of Location'] || ''
                    }));

                    updateFormData({
                        ...formData,
                        childCompanies: childCompaniesData
                    });

                    // Show success modal
                    setSuccessMessage("File uploaded successfully!");
                    setOpenSuccessModal(true);
                };
                reader.readAsBinaryString(file);
            } else if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
                // Handle CSV file with PapaParse
                Papa.parse(file, {
                    complete: (result) => {
                        const childCompaniesData = result.data.map(row => ({
                            locationName: row['Branch Name'] || '',
                            contactName: row['Name of the Contact'] || '',
                            contactEmail: row['Contact Email Address'] || '',
                            locationAddress: row['Name of Location'] || ''
                        }));

                        updateFormData({
                            ...formData,
                            childCompanies: childCompaniesData
                        });

                        // Show success modal
                        setSuccessMessage("File uploaded successfully!");
                        setOpenSuccessModal(true);
                    },
                    header: true,
                });
            }
        }
    };

    return (
        <div className="container mt-5">
            <div className="header">
                <h2 className="text-start">Invite Your Regional Sales Directors/Managers</h2>
                <p className="text-start">Upon submitting this information an invitation is automatically sent to each of them to set up their location's details and photos.</p>
            </div>

            <Formik
                initialValues={{
                    numRepresentatives: formData.numRepresentatives || '',
                    csvFile: formData.csvFile || null,
                }}

                onSubmit={(values) => {
                    updateFormData({ ...formData, ...values }); // Store form data in context
                    next(); // Proceed to the next step
                }}
            >
                {({ setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card p-4 text-start">
                                    <h4>Add Number of Representatives</h4>
                                    <p>Enter the number you wish to invite to create the form to list them.</p>
                                    <div className="form-group">
                                        <label htmlFor="numRepresentatives">
                                            Number of Representatives<span className="text-danger">*</span>
                                        </label>
                                        <Field
                                            type="number"
                                            className="form-control"
                                            id="numRepresentatives"
                                            name="numRepresentatives"
                                            placeholder="Enter Number of Representatives"
                                        />
                                        <ErrorMessage name="numRepresentatives" component="div" className="text-danger" />
                                   <br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-4 text-start">
                                    <h4>Submit a Excel File</h4>
                                    <p>Submit your Excel/CSV file in the correct format with your representatives to be invited.</p>
                                    <div className="form-group">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            startIcon={<FontAwesomeIcon icon={faFileExcel}/>}
                                            className="me-2">
                                            Upload Excel File
                                            <input
                                                type="file"
                                                accept=".csv, .xlsx"
                                                onChange={handleFileUpload}
                                                hidden
                                            />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="dark"
                                            className="me-2"
                                            title="Download and fill the sample file and upload it."
                                            startIcon={<FontAwesomeIcon icon={faDownload}/>}
                                            component="a"
                                            href="sample-yst-residencies.xlsx"
                                            download="sample.xlsx">
                                            Download Sample Excel
                                        </Button>
                                        <ErrorMessage name="csvFile" component="div" className="text-danger"/>
                                        <br/>
                                        <small className="form-text text-muted">
                                             File Format: Make Sure Column Headers are Titled : Location Name ,
                                            Name of the Contact, Contact Email Address , Address of Location
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-2" />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>


            <Dialog
                open={openSuccessModal}
                onClose={() => setOpenSuccessModal(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        width: '80%', // Adjust width as needed
                        maxWidth: '600px', // Max width for larger screens
                    }
                }}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />
                    </div>
                    <p className="text-center mt-3">{successMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        onClick={next}
                        className="next-button btn-danger"
                        endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default InviteRegionalStaff;
