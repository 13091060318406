import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const IndustryCard = ({ logo, title, description, icon, active, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="col-md-3 mb-3"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={`text-start card-industry card ${active ? 'selected' : ''}`} onClick={onClick}>
                <div className="card-body">
                    <FontAwesomeIcon icon={icon} style={{ marginLeft: '8px', marginBottom: '20px' }} size="lg" />
                    <h6 className="card-title d-flex justify-content-between">
                        {title}
                        {active ? (
                            <FontAwesomeIcon
                                className="text-end"
                                icon={faCheckCircle}
                                style={{ marginLeft: '8px' }}
                                size="lg"
                            />
                        ) : null}
                    </h6>

                    {/* Render description items only when hovered */}
                    <div
                        className={`card-text mt-2 ${isHovered ? 'show' : 'hide'}`}
                        style={{ fontSize: '14px' }}
                    >
                        {description.split(',').map((item, index) => (
                            <div key={index} className="d-flex align-items-center mb-1">
                                <FontAwesomeIcon icon={faCheckCircle} className="me-2 " />
                                <span>{item.trim()}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndustryCard;
