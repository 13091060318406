import React, { useState } from "react";
import { useFormContext } from "../context/FormContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Box, CircularProgress, Typography, Chip } from "@mui/material";
import {API_BASE_URL} from "../config";

const ReviewAndSubmit = () => {
    const { formData, resetForm } = useFormContext();
    const [loading, setLoading] = useState(false); // To manage loading state
    const [isSubmitting, setIsSubmitting] = useState(false); // To manage form submission
    const companyLogo = localStorage.getItem("companyLogo");

    const formDataWithCompanyData = {
        ...formData,
        companyLogo,
    };
    const handleSubmit = async () => {
        setLoading(true);
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/save-user-company`, formDataWithCompanyData);
            if (response.status === 200) {
                alert("Form submitted successfully!");

            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again.");
        } finally {
            setLoading(false); // End loading state
        }
    };

    // if (isSubmitting) {
    //     // Show loading screen when form is being submitted
    //     return (
    //         <Box
    //             sx={{
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 position: 'fixed',
    //                 top: 0,
    //                 left: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 zIndex: 9999,
    //                 color: 'white',
    //                 textAlign: 'center',
    //             }}
    //         >
    //             <CircularProgress color="error" size={50} />
    //             <Typography color="black" variant="h6" mt={2}>
    //                 Submitting...
    //             </Typography>
    //             <Typography  color="black" variant="body1">
    //                 Please wait while we are saving your information. We will be sending an invite email to all the users.
    //             </Typography>
    //         </Box>
    //     );
    // }

    return (

        <div className="container text-start my-5 p-4">

            <div className="text-start mb-4">
                <div className="header">
                    <h2>Review Provided Details</h2>
                    <p>Please review all provided details below before submitting the form</p>
                </div>
                {companyLogo && (
                    <img
                        src={companyLogo}
                        alt="Company Logo"
                        className="img-fluid rounded mb-3"
                        style={{ maxHeight: "100px" }}
                    />
                )}
            </div>

            {/* Personal Information */}
            <div className="mb-4">
                <h4>Personal Information</h4>
                <hr/>
                <p><strong>First Name:</strong> {formData.firstName || "N/A"}</p>
                <p><strong>Last Name:</strong> {formData.lastName || "N/A"}</p>
                <p><strong>Email:</strong> {formData.email || "N/A"}</p>
            </div>

            {/* Company Information */}
            <div className="mb-4">
                <h4>Company Information</h4>
                <hr />
                <p><strong>Corporation Name:</strong> {formData.corporationName || "N/A"}</p>
                <p><strong>Office Address:</strong> {formData.officeAddress || "N/A"}</p>
                <p><strong>Phone Number:</strong> {formData.phoneNumber || "N/A"}</p>
                <p><strong>Fax Number:</strong> {formData.faxNumber || "N/A"}</p>
            </div>

            {/* Selected Company */}
            {formData.selectedCompany && (
                <div className="mb-4">
                    <h4>Selected Company</h4>
                    <hr />
                    <p><strong>Company Name:</strong> {formData.selectedCompany || "N/A"}</p>
                    <p><strong>Company Address:</strong> {formData.selectedCompanyAddress || "N/A"}</p>
                    <p><strong>Company Phone:</strong> {formData.selectedCompanyPhone || "N/A"}</p>
                </div>
            )}
            {/* Sales Team */}
            {formData.salesTeams && formData.salesTeams.length > 0 && (
                <div className="mb-4">
                    <h4>Regional Sales Administrator /Directors or Managers</h4>
                    <hr />
                    {/* Headings for larger screens */}
                    <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                        <div className="col-md-3">Location Name</div>
                        <div className="col-md-3">Contact Name</div>
                        <div className="col-md-3">Contact Email</div>
                        <div className="col-md-3">Location Address</div>
                    </div>
                    {/* Data Rows */}
                    {formData.salesTeams.map((team, index) => (
                        <div key={index} className="row mb-3">
                            <div className="col-md-3 d-none d-md-block">{team.locationName || "N/A"}</div>
                            <div className="col-md-3 d-none d-md-block">{team.contactName || "N/A"}</div>
                            <div className="col-md-3 d-none d-md-block">{team.contactEmail || "N/A"}</div>
                            <div className="col-md-3 d-none d-md-block">{team.locationAddress || "N/A"}</div>

                            {/* For smaller screens */}
                            <div className="col-12 d-block d-md-none">
                                <strong>Location Name:</strong> {team.locationName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Name:</strong> {team.contactName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Email:</strong> {team.contactEmail || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Location Address:</strong> {team.locationAddress || "N/A"}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Child Companies */}
            {formData.childCompanies && formData.childCompanies.length > 0 && (
                <div className="mb-4">
                    <h4>Marketing Sales Executive</h4>
                    <hr />
                    {/* Headings for larger screens */}
                    <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                        <div className="col-md-3">Location Name</div>
                        <div className="col-md-3">Contact Name</div>
                        <div className="col-md-3">Contact Email</div>
                        <div className="col-md-3">Location Address</div>
                    </div>
                    {/* Data Rows */}
                    {formData.childCompanies.map((team, index) => (
                        <div key={index} className="row mb-3">
                            {/* For larger screens */}
                            <div className="col-md-3 d-none d-md-block">{team.locationName || "N/A"}</div>
                            <div className="col-md-3 d-none d-md-block">{team.contactName || "N/A"}</div>
                            <div className="col-md-3 d-none d-md-block">{team.contactEmail || "N/A"}</div>
                            <div className="col-md-3 d-none d-md-block">{team.locationAddress || "N/A"}</div>

                            {/* For smaller screens */}
                            <div className="col-12 d-block d-md-none">
                                <strong>Location Name:</strong> {team.locationName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Name:</strong> {team.contactName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Email:</strong> {team.contactEmail || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Location Address:</strong> {team.locationAddress || "N/A"}
                            </div>
                        </div>
                    ))}
                </div>
            )}


            <div className="mb-4">
                <Typography variant="h6" gutterBottom>
                    Additional Information
                </Typography>
                <hr />
                <Typography variant="body1" component="p">
                    <strong>Industry Type:</strong> {formData.industryType || "N/A"}
                </Typography>
                <Typography variant="body1" component="p" mt={2}>
                    <strong>What We Offer:</strong>
                    <Box mt={1}>
                        {formData.whatWeOffer && formData.whatWeOffer.length ? (
                            formData.whatWeOffer.map((offer, index) => (
                                <Chip
                                    key={index}
                                    label={offer.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                    color="primary"
                                    style={{ marginRight: '8px', marginBottom: '8px' }}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                N/A
                            </Typography>
                        )}
                    </Box>
                </Typography>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                    startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                >
                    {loading ? "Submitting..." : "Submit Form"}
                </Button>
            </div>
        </div>
    );
};

export default ReviewAndSubmit;
