import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button, Avatar, Badge } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faArrowRight,
    faTimes,
    faPlus,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CompanySearchSelect from './sections/CompanySearchSelect';
import AddressAutocomplete from "./sections/AddressAutocomplete";
import axios from "axios";
import {API_BASE_URL} from "../config";

const CorporateForm = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();
    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const [isNameTaken, setIsNameTaken] = useState(false);

    const validationSchema = Yup.object({
        corporationName: Yup.string().when('isAddingCompany', {
            is: true,
            then: Yup.string().required('Corporation name is required'),
        }),
        officeAddress: Yup.string().when('isAddingCompany', {
            is: true,
            then: Yup.string().required('Office address is required'),
        })
    });

    const checkCorporationName = async (name) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/search-company-name`, { params: { name } });
            setIsNameTaken(response.data.nameExists);

        } catch (error) {
            console.error("Error checking corporation name:", error);
            setIsNameTaken(false);
        }
    };

    const handleAddressChange = (selectedAddress) => {
        const { label,  ...formattedData } = selectedAddress;
        updateFormData({
            ...formData,
            officeAddress: label ?? "",
            formattedAddress: { ...formattedData },
        });
    };

    const handleClearForm = (resetForm) => {
        resetForm();
        updateFormData({
            selectedCompany: null,
            corporationName: '',
            officeAddress: '',
            formattedAddress : [],
            phoneNumber: '',
            faxNumber: '',
            city: '',
            state: '',
            country: 'US',
        });
        setIsAddingCompany(false);
    };

    return (
        <div className="container mt-5 mb-5 text-start fade-in">
            <div className="header">
                <h2>Add Your Corporate/Company Information</h2>
                <p>This is for the entity and its central location.</p>
            </div>

            {!isAddingCompany && !formData.selectedCompany && !formData.registeredCompanyId && <CompanySearchSelect />}
            {!isAddingCompany && !formData.selectedCompany && !formData.registeredCompanyId && (
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={() => setIsAddingCompany(true)}
                    className="mb-3"
                >
                    If your company is not listed above! Please click here to register it!
                </Button>
            )}

            <Formik
                initialValues={{
                    ...formData
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData(values);
                    if (!isNameTaken) {
                        next();
                    }
                }}>
                {({ handleChange, handleBlur, values, resetForm }) => (
                    <Form className="text-start">
                        {isAddingCompany || formData.corporationName !== "" ? (
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="company-details">
                                        <Avatar src="/placeholder.png" alt="Company Logo" sx={{ width: 56, height: 56 }} />
                                        <div className="form-group">
                                            <label htmlFor="corporationName">
                                                Corporation/Company Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="corporationName"
                                                id="corporationName"
                                                className="form-control"
                                                placeholder="Enter Name of Corporation"
                                                value={values.corporationName}
                                                onChange={handleChange}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    checkCorporationName(e.target.value);
                                                }}
                                            />
                                            {isNameTaken && (
                                                <div className="text-danger mt-2">
                                                    This corporation name is already taken. Please choose another.
                                                </div>
                                            )}
                                            <ErrorMessage name="corporationName" component="div" className="text-danger mt-2" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="officeAddress">
                                                Office Address <span className="text-danger">*</span>
                                            </label>
                                            <AddressAutocomplete
                                                name="officeAddress"
                                                value={values.officeAddress}
                                                onChange={handleAddressChange}
                                            />
                                            <ErrorMessage name="officeAddress" component="div" className="text-danger mt-2" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="city">
                                                City <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="form-control"
                                                value={values?.formattedAddress?.formattedData?.city}
                                            />
                                            <ErrorMessage name="city" component="div" className="text-danger mt-2" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="state">
                                                State <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                className="form-control"
                                                value={values?.formattedAddress?.formattedData?.state}
                                            />
                                            <ErrorMessage name="state" component="div" className="text-danger mt-2" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="country">
                                                Country <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                className="form-control"
                                                value={values?.formattedAddress?.formattedData?.country}
                                            />
                                            <ErrorMessage name="country" component="div" className="text-danger mt-2" />
                                        </div>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleClearForm(resetForm)}
                                            className="mb-3 ms-3"
                                            style={{ width: 'auto' }}>
                                            Clear Form
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : ''}

                        <hr style={{ margin: '10px 0' }} />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CorporateForm;
