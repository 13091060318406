// WelcomeBackPopup.js

import React, { useState, useEffect } from 'react';
import { Modal, Button } from '@mui/material';
import { useFormContext } from '../context/FormContext';

const WelcomeBackPopup = () => {
    const { hasStoredData, resetForm, nextStep } = useFormContext();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (hasStoredData) {
            setOpen(true);
        }
    }, [hasStoredData]);

    const handleContinue = () => {
        setOpen(false);
        const storedStep = localStorage.getItem('currentStep');
        nextStep(storedStep);
    };

    const handleStartOver = () => {
        resetForm(); // Clear stored data and start over
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div style={modalStyle}>
                <h4 className="text-start">Start Where You Left Off ? </h4>
                <p className="text-start">You have saved progress. Would you like to continue where you left off or start over?</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button variant="outlined" color="success" onClick={handleStartOver}>
                        Start Over
                    </Button>

                    <Button variant="contained" color="danger" onClick={handleContinue}>
                        Continue
                    </Button>

                </div>
            </div>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    textAlign: 'center',
};

export default WelcomeBackPopup;
