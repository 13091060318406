import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { entityTypes } from '../constants';

const EntityType = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();

    const validationSchema = Yup.object({
        entityType: Yup.string().required('Please select an entity type'),
    });

    return (
        <div className="container align-items-center mt-5 mb-5 fade-in">
            <div className="header">
                <h2 className="text-start mb-2">Select Entity Type</h2>
                <p className="text-start mb-4">
                    The entity to represent, oversee and manage Your Business
                </p>
            </div>
            <Formik
                initialValues={{ entityType: formData.entityType }} // Use entityType from formData
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData(values);
                    next();
                }}>
                {({ handleChange, values }) => (
                    <Form className="w-100">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <RadioGroup name="entityType" value={values.entityType} onChange={handleChange}>
                                    {entityTypes.map((type) => (
                                        <FormControlLabel
                                            key={type.value}
                                            value={type.value}
                                            control={<Radio />}
                                            label={type.label}
                                        />
                                    ))}
                                </RadioGroup>
                                <ErrorMessage name="entityType" component="div" className="text-danger" />
                            </div>
                        </div>

                        <hr style={{ margin: '20px 0' }} />

                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntityType;
