import React, { useEffect, useRef, useState } from 'react';

const AddressAutocomplete = ({ name, value, onChange }) => {
    const inputRef = useRef(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [address, setAddress] = useState(value || '');

    useEffect(() => {
        if (window.google) {
            const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
                types: ['geocode'],
                componentRestrictions: { country: 'us' },
            });

            autocompleteInstance.addListener('place_changed', () => {
                const place = autocompleteInstance.getPlace();
                if (place && place.formatted_address) {
                    const addressComponents = place.address_components || [];
                    const getAddressComponent = (type) =>  addressComponents.find((component) => component.types.includes(type))?.long_name || '';
                    const getShortAddressComponent = (type) =>  place.address_components?.find((component) => component.types.includes(type))?.short_name || '';
                    const formattedData = {
                        label: place.formatted_address || '',
                        value: place.place_id || '',
                        address: getAddressComponent('street_address') || getAddressComponent('route') || '',
                        city: getAddressComponent('locality'),
                        state: getAddressComponent('administrative_area_level_1'),
                        stateCode: getShortAddressComponent('administrative_area_level_1'),
                        country: getAddressComponent('country'),
                        zipcode: getAddressComponent('postal_code'),
                    };
                    setAddress(place.formatted_address);
                    onChange({ label: place.formatted_address, value: place.place_id ,
                        formattedData : formattedData
                  });
                }
            });

            setAutocomplete(autocompleteInstance);
        }
    }, []);

    useEffect(() => {
        if (value?.label && inputRef.current) {
            setAddress(value.label);
        }
    }, [value]);

    return (
        <div className="autocomplete-container">
            <input
                ref={inputRef}
                type="text"
                name={name}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                placeholder="Start typing address"
            />
        </div>
    );
};

export default AddressAutocomplete;
