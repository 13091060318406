import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { industryTypes } from '../constants';
import IndustryCard from "./sections/IndustryCard";

const IndustryType = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();
    const [selectedIndustry, setSelectedIndustry] = useState(formData.industryType || '');

    const validationSchema = Yup.object({
        industryType: Yup.string().required('Please select an industry'),
    });

    const handleCardClick = (type, setFieldValue) => {
        setSelectedIndustry(type.title);
        setFieldValue('industryType', type.title);
        updateFormData(
            { industryType: type.title,
                whatWeOffer : []
            }
        );
    };

    return (
        <div className="container align-items-center mt-5 mb-5 fade-in">
            <div className="header">
                <h2 className="text-start mb-2">Let's get Started!</h2>
                <p className="text-start mb-4">
                    Choose your industry from the list; if not listed, please choose "Other"
                </p>
            </div>
            <Formik
                initialValues={{ industryType: formData.industryType || '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData(values);
                    next();
                }}
            >
                {({ setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">
                            {industryTypes.map((type, index) => (
                                <IndustryCard
                                    key={index}
                                    logo={type.logo}
                                    title={type.title}
                                    description={type.description}
                                    listItems={type.listItems}
                                    icon={type.icon}
                                    active={selectedIndustry === type.title}
                                    onClick={() => handleCardClick(type, setFieldValue)}
                                />
                            ))}
                            <ErrorMessage name="industryType" component="div" className="text-danger" />
                        </div>

                        <hr style={{ margin: '20px 0' }} />

                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IndustryType;
