import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Checkbox, FormControlLabel, Button, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { industryTypes } from '../constants';

const WhatWeOffer = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();
    const [customCategories, setCustomCategories] = useState([]); // State for storing custom categories
    const [newCategory, setNewCategory] = useState(''); // Input field value

    const validationSchema = Yup.object({
        whatWeOffer: Yup.array().min(1, 'Please select at least one category'),
    });

    const selectedIndustry = industryTypes.find(type => type.title === formData.industryType);
    const categories = selectedIndustry ? selectedIndustry.categories : [];

    const handleAddCategory = (e, setFieldValue, values) => {
        e.preventDefault();
        const trimmedCategory = newCategory.trim();

        if (trimmedCategory && !customCategories.includes(trimmedCategory)) {
            // Add the new category to custom categories
            const updatedCustomCategories = [...customCategories, trimmedCategory];
            setCustomCategories(updatedCustomCategories);

            // Update the Formik `whatWeOffer` field to include the new category
            const updatedWhatWeOffer = [...values.whatWeOffer, trimmedCategory];
            setFieldValue('whatWeOffer', updatedWhatWeOffer);
        }

        setNewCategory(''); // Clear the input field
    };

    return (
        <div className="container align-items-center mt-5 mb-5 fade-in">
            <div className="header">
                <h2 className="text-start mb-2">What We Offer</h2>
                <p className="text-start mb-4">
                    Please select the type(s) of product or service you offer.
                </p>
            </div>
            <Formik
                initialValues={{
                    whatWeOffer: formData.whatWeOffer || [],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData(values);
                    next();
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="checkbox-group">
                                    {categories.map((category) => (
                                        <FormControlLabel
                                            key={category.value}
                                            control={
                                                <Checkbox
                                                    name="whatWeOffer"
                                                    value={category.value}
                                                    checked={values.whatWeOffer.includes(category.value)}
                                                    onChange={() => {
                                                        const currentValues = values.whatWeOffer;
                                                        if (currentValues.includes(category.value)) {
                                                            setFieldValue(
                                                                'whatWeOffer',
                                                                currentValues.filter((item) => item !== category.value)
                                                            );
                                                        } else {
                                                            setFieldValue('whatWeOffer', [...currentValues, category.value]);
                                                        }
                                                    }}
                                                />
                                            }
                                            label={category.name}
                                        />
                                    ))}

                                    {/* If the selected industry is "Other," show an input field */}
                                    {formData.industryType === "Other" && (
                                        <>
                                            {customCategories.map((category, index) => (
                                                <FormControlLabel
                                                    key={`custom-${index}`}
                                                    control={
                                                        <Checkbox
                                                            name="whatWeOffer"
                                                            value={category}
                                                            checked={values.whatWeOffer.includes(category)}
                                                            onChange={() => {
                                                                const currentValues = values.whatWeOffer;
                                                                if (currentValues.includes(category)) {
                                                                    setFieldValue(
                                                                        'whatWeOffer',
                                                                        currentValues.filter((item) => item !== category)
                                                                    );
                                                                } else {
                                                                    setFieldValue('whatWeOffer', [...currentValues, category]);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={category}
                                                />
                                            ))}

                                            <div className="custom-category-input mt-3">
                                                <TextField
                                                    fullWidth
                                                    value={newCategory}
                                                    onChange={(e) => setNewCategory(e.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleAddCategory(e, setFieldValue, values);
                                                        }
                                                    }}
                                                    label="Add a category"
                                                    variant="outlined"
                                                    placeholder="Type category name and press Enter"
                                                />
                                                <Button
                                                    type="button"
                                                    onClick={(e) => handleAddCategory(e, setFieldValue, values)}
                                                    variant="contained"
                                                    color="primary"
                                                    className="mt-2"
                                                >
                                                    Add to List
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <ErrorMessage name="whatWeOffer" component="div" className="text-danger" />
                            </div>
                        </div>

                        <hr style={{ margin: '20px 0' }} />

                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default WhatWeOffer;
