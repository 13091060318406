import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import PlacesAutocomplete from 'react-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCancel, faCross, faTimes, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {Button} from "@mui/material";

const ChildCompanyForm = ({ index, company , onRemove }) => {
    const { values, handleChange, handleBlur } = useFormikContext();

    const childCompany = values.childCompanies[index] || {
        locationName: '',
        contactName: '',
        contactEmail: '',
        locationAddress: '',
    };

    const handleAddressChange = (address) => {
        handleChange({
            target: {
                name: `childCompanies[${index}].locationAddress`,
                value: address,
            }
        });
    };

    const handleAddressSelect = (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
            console.log(results)
            if (status === 'OK' && results[0]) {
                handleChange({
                    target: {
                        name: `childCompanies[${index}].locationAddress`,
                        value: address,
                    }
                });
            }
        });
    };

    return (
        <div className="child-company-form row">
            <div className="form-group col-md-3">
                <input
                    type="text"
                    name={`childCompanies[${index}].locationName`}
                    id={`childCompanies[${index}].locationName`}
                    className="form-control"
                    placeholder="Enter Child Company Name"
                    value={childCompany.locationName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ErrorMessage name={`childCompanies[${index}].locationName`} component="div" className="text-danger mt-2"/>
            </div>


            <div className="form-group col-md-2">
                <input
                    type="text"
                    name={`childCompanies[${index}].contactName`}
                    id={`childCompanies[${index}].contactName`}
                    className="form-control"
                    placeholder="Enter Phone Number"
                    value={childCompany.contactName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ErrorMessage name={`childCompanies[${index}].contactName`} component="div" className="text-danger mt-2"/>
            </div>

            <div className="form-group col-md-3">
                <input
                    type="text"
                    name={`childCompanies[${index}].contactEmail`}
                    id={`childCompanies[${index}].contactEmail`}
                    className="form-control"
                    placeholder="Enter Contact Email "
                    value={childCompany.contactEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ErrorMessage name={`childCompanies[${index}].contactEmail`} component="div" className="text-danger mt-2"/>
            </div>


            <div className="form-group col-md-3">
                    <PlacesAutocomplete
                        value={childCompany.locationAddress}
                        onChange={handleAddressChange}
                        onSelect={handleAddressSelect}>
                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder: "Search and select office address",
                                    className: "form-control",
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                    const {key, ...restProps} = getSuggestionItemProps(suggestion, {
                                        className: suggestion.active ? "suggestion-item--active" : "suggestion-item",
                                    });

                                    return (
                                        <div
                                            key={key}
                                            {...restProps}>
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <ErrorMessage name={`childCompanies[${index}].locationAddress`} component="div" className="text-danger mt-2"/>
            </div>
            <div className="form-group col-md-1">
                <p> Remove
                <FontAwesomeIcon
                    icon={faTimes}
                    onClick={onRemove}
                    style={{ fontSize: '1.2rem', cursor: 'pointer', marginLeft: '8px', color: '#ff4d4f' }}/>
                </p>
            </div>

        </div>

    );
};

export default ChildCompanyForm;
